<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="search.kname" @change="Search" placeholder="模糊搜索名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select clearable v-model="search.pid" @change="Search" placeholder="上级菜单">
            <el-option :value="-1" label="顶级菜单"></el-option>
            <el-option v-for="item in allMenulist" v-bind:key="item.id" v-bind:label="item.name" v-bind:value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="addmenu">添加菜单</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="菜单名称">
        <template v-slot="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="页面路径">
        <template v-slot="scope">
          <span>{{ scope.row.route }}</span>
        </template>
      </el-table-column>
      <el-table-column label="父菜单名称">
        <template v-slot="scope">
          <span>{{ scope.row.parent_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button type="info" size="small" @click="gofunc(scope.row.id)">功能</el-button>
          <el-button type="primary" size="small" @click="editmenu(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="deletemenu(scope.row.id)">删除</el-button>
          <i style="font-weight: 700" class="el-icon-top sort" @click="menuSort(scope.row,2)"></i>
          <i style="font-weight: 700" class="el-icon-bottom sort" @click="menuSort(scope.row,1)"></i>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 新增修改 -->
    <el-dialog :title="title+'菜单'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <!-- <el-form-item label="图标名称" label-width="100px">
          <el-input v-model="form.ico" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-form-item label="菜单名称" prop="name" label-width="100px">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="上级菜单" label-width="100px">
          <el-select v-model="form.pid" placeholder="">
            <el-option :value="-1" label="顶级菜单"></el-option>
            <el-option v-for="item in allMenulist" v-bind:key="item.id" v-bind:label="item.name" v-bind:value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="页面路径" prop="route" label-width="100px">
          <el-input v-model="form.route" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="全球菜单" prop="is_global" label-width="100px">
          <el-select v-model="form.is_global" placeholder="">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区域菜单" prop="is_region" label-width="100px">
          <el-select v-model="form.is_region" placeholder="">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
          <el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        kname: '',
        pid: '',
      },
      list: [],
      total: 0,
      dialogFormVisible: false,
      title: '',
      form: {
        id: '',
        ico: '',
        name: '',
        pid: '',
        route: '',
        is_global: '',
        is_region: '',
      },
      rules: {
        name: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }],
        route: [{ required: true, message: '请输入页面路径', trigger: 'blur' }],
        is_global: [{ required: true, message: '请选择是否为全球菜单', trigger: 'blur' }],
        is_region: [{ required: true, message: '请选择是否为区域菜单', trigger: 'blur' }],
      },
      edit: false,
      allMenulist: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    gofunc (item) {
      this.$router.push('/admin/menu/menufunc/' + item)
    },
    GetAllmenu() {
      this.api.MenuList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.allMenulist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search () {
      this.api.MenuList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    menuSort (item,direction) {
      this.api.MenuSort({
        action: direction,
        id: item.id
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('修改排序成功')
          this.Search()
          return
        }
        this.$message.error('修改排序失败' + res.data.msg)
      })
    },
    addmenu () {
      // 清空表单验证
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.form = {}
      this.edit = false
      this.title = '新增'
      this.dialogFormVisible = true
      this.form.pid = this.search.pid==0?'':this.search.pid
    },
    editmenu(item) {
      this.form = {}
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.edit = true
      this.title = '修改'
      this.dialogFormVisible = true
      this.api.MenuDetail({id:item.id}).then(res => {
        if (res.data.code == 200) {
          this.form=res.data.data
          return
        }
        this.$message.error('获取详情失败' + res.data.msg)
      })
    },
    deletemenu(item) {
      this.$confirm('是否删除该菜单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.api.MenuDelete({id: item}).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('获取失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    update(){
      this.api.MenuUpdate({
        id: +this.form.id,
        ico: this.form.ico,
        name: this.form.name,
        pid: +this.form.pid,
        route: this.form.route,
        is_global: this.form.is_global,
        is_region: this.form.is_region,
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.title +'成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error(this.title +'失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    addconfirm () {
      this.update()
    },
    editconfirm () {
      this.update()
    },
  },
  filters: {},
  mounted () {
    this.Search()
  },
  created () {
    this.GetAllmenu()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.sort {
  font-size: 16px;
  margin: 0 5px;
  cursor: pointer;
  font-weight: 700px!important;
}
</style>
